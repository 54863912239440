import { useEffect } from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider, Route, ScrollRestoration, useLocation, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Imprint } from './pages/Imprint';
import { LoadingScreen } from './compositions/LoadingScreen';

function App() {

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="App">
      <LoadingScreen />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

export const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <ScrollRestoration />
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <Home key="home" />
          }
        />
        <Route
          path="/about"
          element={
            <About key="about" />
          }
        />
        <Route
          path="/imprint"
          element={
            <Imprint key="imprint" />
          }
        />
      </Routes>
    </AnimatePresence>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <AnimatedRoutes />,
    children: [
      {
        path: "/",
        element: <Home key="home" />
      },
      {
        path: "/about",
        element: <About key="about" />
      },
      {
        path: "/imprint",
        element: <Imprint key="imprint" />
      }
    ]
  }
]);
