import { motion } from 'framer-motion';
import { FC } from 'react';

export const TextSplitter: FC<{
    text: string
}> = ({
    text
}) => {
    return (
        <>
            {text.split(' ').map((item, index) => {
                return (
                    <motion.span
                        key={'item'+index}
                        style={{
                            display: 'inline-block',
                            overflow: 'hidden',
                        }}
                    >
                        <motion.span
                        className='inner-text-split'
                          style={{
                            display: 'inline-block',
                            y: -100,
                            whiteSpace: 'pre',
                        }}
                        >
                            {item + ' '}{' '}
                        </motion.span>
                        <span>
                        {' '}
                    </span>
                    </motion.span>
                
                )
            })}
        </>
    )
}
