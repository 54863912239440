import { Link } from "react-router-dom"
import { Link as StyledLink } from "../components/Link"

export const Navbar = () => {
    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    top: '2rem',
                    width: '200px',
                    height: 'auto',
                    margin: 'auto',
                    left: '2rem',
                    textAlign: 'left',
                    zIndex: 10,
                    color: '#000000',
                }}
            >
                <StyledLink
                    text="Brutal Geometric"
                    href="/"
                    color="#000000"
                />
            </div>

            <Link
                to="/about"
                style={{
                    position: 'fixed',
                    top: '2rem',
                    width: '100px',
                    height: 'auto',
                    margin: 'auto',
                    right: '2rem',
                    textAlign: 'right',
                    zIndex: 10,
                    color: '#000000',
                }}
            >
                <StyledLink
                    text="Home , "
                    href="/"
                    color="#000000"
                />
                <StyledLink
                    text="About"
                    href="/about"
                    color="#000000"
                />
            </Link>
        </>
    )
}
