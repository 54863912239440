import { motion } from "framer-motion"
import { FC } from "react"
import styled from "styled-components"

const UnderlineWrapper = styled(motion.div)`
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
`

const Line = styled(motion.div)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #000;
`

type Props = {
    children: any;
    active?: boolean;
}

export const UnderlineLink: FC<Props> = ({
    children,
    active,
}) => {
    return (
        <UnderlineWrapper
            initial="IDLE"
            whileHover="HOVER"
            whileTap="TAP"
            animate={active ? 'ACTIVE' : undefined}
            variants={{
                'IDLE': {
                    transform: 'scale(1)'
                },
                'TAP': {
                    transform: 'scale(.9)'
                }
            }}
        >
            {children}

            <Line
                variants={{
                    'IDLE': {
                        left: '-100%',
                    },
                    'HOVER': {
                        left: '0%',
                    },
                    'ACTIVE': {
                        left: '0%',
                    }
                }}
            />

            <Line
                variants={{
                    'IDLE': {
                        left: '-100%',
                        transition: {
                            delay: .5,
                        }
                    },
                    'HOVER': {
                        left: '0%',
                        transition: {
                            delay: .5,
                        }
                    },
                }}
            />
        </UnderlineWrapper>
    )
}
