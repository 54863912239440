import { Navbar } from "../compositions/Navbar";
import { motion, stagger, useAnimate } from 'framer-motion';
import { Footer } from "../compositions/Footer";
import { PageTransition } from "../compositions/PageTransition";

import Selfie from './../assets/content/DSC00590.jpg';
import { UnderlineLink } from "../components/UnderlineLink";
import styled from "styled-components";
import { useEffect } from "react";
import { ANIMATION_EASE } from "../constants/animation.constants";
import { TextSplitter } from "../components/TextSplitter";

const GridImage = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 2rem;
    margin-top: 8rem;

    @media screen and (min-width: 420px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .grid-image-item {
        grid-column: 2 / 4;
        text-align: right;

        @media screen and (min-width: 420px) {
            font-size: 8rem;
            font-weight: bold;
            text-align: left;
        }
    }
`

const GridOne = styled(motion.div)`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem;

    @media screen and (min-width: 420px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .inner-item {
        font-size: 2rem;
        font-weight: normal;
        font-weight: bold;

        @media screen and (min-width: 420px) {
            grid-column: 2 / 4;
            font-size: 6rem;
            line-height: 6rem;
            font-weight: bold;
        }
    }
`

const GridTwo = styled(motion.div)`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem;

    @media screen and (min-width: 420px) {
        grid-template-columns: repeat(3, 1fr);
    }

    #itemfive {
        line-height: 2rem;
        font-size: 1.5rem;

        @media screen and (min-width: 420px) {
            line-height: 2.75rem;
            font-size: 2rem;
        }
    }
`

export const About = () => {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        animate([
            // STAGE 1
            [
                "#itemone",
                { opacity: 1, y: 0 },
                { ease: ANIMATION_EASE, duration: 1, at: '<' }
            ],
            [
                "#itemtwo",
                { opacity: 1, y: 0 },
                { ease: ANIMATION_EASE, duration: 1, at: '-0.75' }
            ],
            [
                "#itemthree",
                { opacity: 1, y: 0 },
                { ease: ANIMATION_EASE, duration: 1, at: '-0.75' }
            ],
            [
                "#itemthree span .inner-text-split",
                { y: 0 },
                { ease: ANIMATION_EASE, duration: 1, at: '-0.75', delay: stagger(.1) }
            ],

            [
                "#itemfour",
                { opacity: 1, y: 0 },
                { ease: ANIMATION_EASE, duration: 1, at: '-0.75' }
            ],
            [
                "#itemfive",
                { opacity: 1, y: 0 },
                { ease: ANIMATION_EASE, duration: 1, at: '<' }
            ],
            [
                "#itemsix",
                { opacity: 1, y: 0 },
                { ease: ANIMATION_EASE, duration: 1, at: '-0.75' }
            ],
            [
                "#itemseven",
                { opacity: 1, y: 0 },
                { ease: ANIMATION_EASE, duration: 1, at: '<' }
            ],
            [
                "#itemeight",
                { opacity: 1, y: 0 },
                { ease: ANIMATION_EASE, duration: 1, at: '-0.75' }
            ],
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            ref={scope}
        >
            <Navbar />
            <PageTransition />
            <motion.main
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <GridImage>
                    <div>
                    </div>
                    <div
                        className="grid-image-item"
                    >
                        <motion.img
                            src={Selfie}
                            alt="asdfasdf"
                            id="itemone"
                            style={{
                                maxWidth: '150px',
                                aspectRatio: '2/3',
                                objectFit: 'cover',
                                opacity: '0',
                                y: 20,
                            }}
                        />
                    </div>
                    <div></div>
                </GridImage>

                <GridOne>
                    <motion.div
                        id="itemtwo"
                        style={{
                            opacity: '0',
                            y: 20,
                        }}
                    >
                        (001)
                    </motion.div>
                    <motion.div
                        className="inner-item"
                        id="itemthree"
                        style={{
                            opacity: '0',
                            y: 20,
                        }}
                    >
                        <TextSplitter
                            text="Searching for everything and nothing."
                        />
                    </motion.div>
                </GridOne>

                <GridTwo>
                    <motion.div
                        id="itemfour"
                        style={{
                            opacity: '0',
                            y: 20,
                        }}
                    >
                        (002)
                    </motion.div>
                    <motion.div
                        id="itemfive"
                        style={{
                            opacity: '0',
                            y: 20,
                        }}
                    >
                        Kajetan Powolny, born in 1999, based in Vienna, Austria.
                    </motion.div>
                    <motion.div
                        id="itemsix"
                        style={{
                            opacity: '0',
                            y: 20,
                        }}
                    >
                    </motion.div>
                </GridTwo>
                <GridOne>
                    <motion.div
                        id="itemseven"
                        style={{
                            opacity: '0',
                            y: 20,
                        }}
                    >
                        (003)
                    </motion.div>
                    <motion.div
                        id="itemeight"
                        style={{
                            lineHeight: '1.5rem',
                            opacity: '0',
                            y: 20,
                        }}
                    >
                        <div>
                            <a href="https://www.instagram.com/brutalgeometric/" target="_blank" rel="noreferrer">
                                <UnderlineLink>
                                    Instagram
                                </UnderlineLink>
                            </a>
                        </div>
                        <div>
                            <a href="https://unsplash.com/@brutalgeometric" target="_blank" rel="noreferrer">
                                <UnderlineLink>
                                    Unsplash
                                </UnderlineLink>
                            </a>
                        </div>
                        <div>
                            <a href="mailto:hello@brutalgeometric.studio" target="_blank" rel="noreferrer">
                                <UnderlineLink>
                                    E-Mail
                                </UnderlineLink>
                            </a>
                        </div>
                    </motion.div>
                </GridOne>
                <Footer />
            </motion.main>
        </div>
    )
}
