import { motion } from 'framer-motion';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
    text: string;
    href?: string;
    color?: string,
}

export const Link: FC<Props> = ({
    href = '',
    text,
    color = '#fff'
}) => {
    function splitStringTop(str: string,) {
        const splittedText: any[] = [];

        for (let i = 0; i < str.length; i += 1) {
            if (str.substring(i, i + 1) === ' ') splittedText.push(<span>{' '}</span>)

            splittedText.push(
                <motion.span
                    style={{
                        display: 'inline-block'
                    }}
                    variants={{
                        hover: {
                            transform: 'translateY(-100%)',
                        },
                        idle: {
                            transform: 'translateY(0%)',
                        }
                    }}
                >
                    {str.substring(i, i + 1)}
                </motion.span>
            );
        }

        return splittedText;
    }

    function splitStringBottom(str: string,) {
        const splittedText: any[] = [];

        for (let i = 0; i < str.length; i += 1) {
            if (str.substring(i, i + 1) === ' ') splittedText.push(<span>{' '}</span>)

            splittedText.push(
                <motion.span
                    style={{
                        display: 'inline-block',
                    }}
                    variants={{
                        hover: {
                            transform: 'translateY(-100%)',
                        },
                        idle: {
                            transform: 'translateY(0%)',
                        }
                    }}
                >
                    {str.substring(i, i + 1)}
                </motion.span>
            );
        }

        return splittedText;
    }

    return (
        <RouterLink
            to={href}
        >
            <motion.span
                style={{
                    overflow: 'hidden',
                    display: 'inline-block',
                    position: 'relative',
                    verticalAlign: 'middle',
                }}
                initial="idle"
                whileHover="hover"
            >
                <motion.div
                    style={{
                        color: color,
                        textDecoration: 'none',
                        display: 'inline-block',
                        position: 'relative',
                        
                    }}
                    variants={{
                        hover: {
                            transition: {
                                staggerChildren: .025,
                                delayChildren: .05,
                            }
                        },
                        idle: {
                            transition: {
                                staggerChildren: .025,
                                delayChildren: .05,
                            }
                        }
                    }}
                >
                    <div
                        style={{
                            lineHeight: '1.5rem',
                        }}
                    >{splitStringTop(text)}</div>
                </motion.div>
                <motion.div
                    style={{
                        color: color,
                        textDecoration: 'none',
                        display: 'inline-block',
                        position: 'absolute',
                        width: '100%',
                        left: 0,
                        bottom: '-100%',
                    }}
                    variants={{
                        hover: {
                            transition: {
                                staggerChildren: .025,
                                delayChildren: .05,
                            }
                        },
                        idle: {
                            transition: {
                                staggerChildren: .025,
                                delayChildren: .05,
                            }
                        }
                    }}
                >
                    <div
                        style={{
                            lineHeight: '1.5rem',
                        }}
                    >{splitStringBottom(text)}</div>
                </motion.div>
            </motion.span>
        </RouterLink>
    )
}
