import styled from "styled-components"
import { Link } from "../components/Link"
import { motion } from "framer-motion"

const FooterWrapper = styled(motion.footer)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem;
    text-transform: uppercase;

    @media screen and (min-width: 420px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

export const Footer = () => {
    return (
        <FooterWrapper>
            <div>
                <Link
                    href="/imprint"
                    text="IMPRINT"
                    color="#000"
                />
            </div>
            <div>
                © 2024 BRUTAL GEOMETRIC ALL RIGHTS RESERVED
            </div>
            <div>
                Made by
                {' '}
                <Link
                    href="https://distortedvisuals.studio"
                    text="Distorted Visuals ®"
                    color="#000"
                />
                {' '}
                Studio
            </div>
        </FooterWrapper>
    )
}
