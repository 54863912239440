import { motion } from 'framer-motion';
import { ANIMATION_EASE } from '../constants/animation.constants';

export const PageTransition = () => {
    return (
        <>
            <motion.div
                initial={{
                    width: '100vw',
                    height: '100vh',
                    background: '#0d0d0d',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    transform: 'translateY(0%)',
                    zIndex: 9999999,
                }}
                animate={{
                    transform: 'translateY(100%)',
                    transition: {
                        duration: 1,
                        ease: ANIMATION_EASE,
                    }
                }}
                exit={{
                    transform: 'translateY(100%)',
                }}
            >
            </motion.div>

            <motion.div
                initial={{
                    width: '100vw',
                    height: 'calc(var(--vh, 1vh) * 100)',
                    background: '#0d0d0d',
                    transform: 'translateY(-100%)',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 9999999,
                }}
                animate={{
                    transform: 'translateY(-100%)',
                }}
                exit={{
                    transform: 'translateY(0%)',
                    transition: {
                        duration: 1,
                        ease: ANIMATION_EASE,
                    }
                }}
            >
            </motion.div>
        </>
    )
}
