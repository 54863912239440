import { Navbar } from "../compositions/Navbar";
import { motion } from 'framer-motion';
import { Footer } from "../compositions/Footer";
import { PageTransition } from "../compositions/PageTransition";

export const Imprint = () => {

    return (
        <>
            <Navbar />
            <PageTransition />
            <motion.main
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    top: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <div
                    className="scaled-header"
                    style={{
                        padding: '12rem 2rem 2rem 2rem',
                    }}
                >
                    Imprint
                </div>
                <div
                    style={{
                        fontSize: 'clamp(2rem, 6vw, 3rem)',
                        lineHeight: 'clamp(2.5rem, 7vw, 4rem)',
                        padding: '2rem',
                        wordBreak: 'break-word',
                    }}
                >
                    Kajetan Powolny
                    Zwinzstraße 5/1/14
                    1160 Vienna
                    Email: hello[@]distortedvisuals.studio
                    Website: distortedvisuals.studio
                    VAT Number: ATU76524646
                    GISA Number: 31498906
                    Memberships in the Chamber of Commerce Organization:
                    Member of WKÖ, WKNÖ
                    Trade Regulations: www.ris.bka.gv.at, Free Trade

                    Consumers have the option to direct complaints to the EU Online Dispute Resolution Platform: http://ec.europa.eu/odr. You can also address any complaints to the email address provided above.

                    If you have any questions, feel free to write me an email at hello[@]distortedvisuals.studio. I will gladly take the time to answer everything. If there are still any uncertainties, we can also have a phone call.
                </div>
                <Footer />
            </motion.main>
        </>
    )
}
