import { FC, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { PageTransition } from "../compositions/PageTransition";
import { Navbar } from "../compositions/Navbar";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Image, Scroll, ScrollControls, useScroll } from "@react-three/drei";
import * as THREE from "three";
import { motion } from 'framer-motion';

export const Home = () => {
    const [progress, setProgress] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    return (
        <>
            <PageTransition />
            <Navbar />
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: 'calc(var(--vh, 1vh) * 100)',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '2rem',
                        width: '200px',
                        height: 'auto',
                        margin: 'auto',
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                    }}
                >
                    {progress}%
                </div>

                <Canvas
                    style={{
                        width: '100vw',
                        height: '100vh',
                    }}
                    camera={{
                        position: [0, 0, 0]
                    }}
                    gl={{
                        outputColorSpace: THREE.SRGBColorSpace,
                    }}
                >
                    <ResponsiveOrthographicCamera makeDefault position={[0, 0, 0]} zoom={window.innerWidth < 600 ? 0.25 : 0.5} near={0.001} far={1000} />
                    <ambientLight intensity={1} />

                    <ScrollControls pages={3} damping={0.1} infinite>
                        {/* Canvas contents in here will *not* scroll, but receive useScroll! */}
                        <ScrollGroup setProgress={setProgress} setIsScrolling={setIsScrolling} />
                        <Scroll />
                    </ScrollControls>
                </Canvas>

                <motion.div
                    animate={isScrolling ? 'HIDE' : 'SHOW'}
                    style={{
                        position: 'absolute',
                        bottom: '2rem',
                        width: '200px',
                        height: 'auto',
                        margin: 'auto',
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '.5rem',
                    }}
                    variants={{
                        'SHOW': {
                            y: 0,
                        },
                        'HIDE': {
                            y: 40,
                        }
                    }}
                >
                    Scroll down
                </motion.div>
            </div>
        </>
    )
}

function ScrollGroup(props: any) {
    const ref: any = useRef(null)
    const data = useScroll()
    const lastOffsetRef = useRef(0);

    useFrame(() => {
        // data.offset = current scroll position, between 0 and 1, dampened
        // data.delta = current delta, between 0 and 1, dampened

        const scrollSpeed = data.offset - lastOffsetRef.current;
        lastOffsetRef.current = data.offset;

        if (scrollSpeed !== 0) {
            props.setIsScrolling(true);
        } else {
            props.setIsScrolling(false);
        }

        if (ref.current) {
            ref.current.rotation.z = -Math.PI * 2 * data.offset;
        }

        if (data.offset > 0) {
            props.setProgress(Number(data.offset * 100).toFixed(0));
        } else {
            props.setProgress(0);
        }
    })
    return <>
        <group
            ref={ref}
            position={[0, 0, 0]}
            rotation={[Math.PI / 2, 0, 0]}
        >
            <ImagesOnCircle
                imageUrls={[
                    {
                        image: '/DSCF6353.jpg',
                        ratio: '2/3',
                    },
                    {
                        image: '/IMG_2845.jpg',
                        ratio: '2/2',
                    },
                
                    {
                        image: '/DSCF6647.jpg',
                        ratio: '2/3',
                    },
                
                    {
                        image: '/DSCF6946.jpg',
                        ratio: '2/2.5',
                    },
                   
                    {
                        image: '/DSCF0378-2.jpg',
                        ratio: '2/3',
                    },
                    {
                        image: '/img20240618_19195832.jpg',
                        ratio: '2/2.5',
                    },
                  

                    // FIRST
                    {
                        image: '/DSCF6312.jpg',
                        ratio: '2/3',
                    },
                    {
                        image: '/DSCF6516.jpg',
                        ratio: '2/2.5',
                    },
                    
                ]}
                radius={4}
            />

        </group>
    </>
}

const ImagesOnCircle: React.FC<any> = ({ imageUrls, radius }) => {
    const n = imageUrls.length;
    const images = imageUrls.map((item: any, i: number) => {
        const angle = (2 * Math.PI * i) / n;
        const x = radius * Math.cos(angle);
        const y = radius * Math.sin(angle);
        return <ImageFunction key={i} url={item.image} ratio={item.ratio} x={x} y={y} />;
    });

    return <>{images}</>;
};


const ImageFunction: FC<any> = ({
    url,
    x,
    y,
    i,
    ratio
}) => {
    const ref: any = useRef(null)
    const lastOffsetRef = useRef(0);
    const data = useScroll()

    const widthRatio = ratio.split('/')[0];
    const heightRatio = ratio.split('/')[1];

    useFrame(() => {
        // data.offset = current scroll position, between 0 and 1, dampened
        // data.delta = current delta, between 0 and 1, dampened

        const scrollSpeed = data.offset - lastOffsetRef.current;
        lastOffsetRef.current = data.offset;

        // Optional: Log the scroll speed for debugging

        const scaleFactor = -0.5 < scrollSpeed && scrollSpeed < 0.5 ? Math.abs(scrollSpeed) * 8 : 0; // Example scaling factor
        const limitScale = scaleFactor > 0.5 ? 0.5 : scaleFactor;
        const newScaleX = widthRatio - (1 * limitScale);
        const newScaleY = heightRatio - (1 * limitScale);

        console.log('scrollSpeed', scaleFactor)

        if (ref.current) {
            ref.current.lookAt(new THREE.Vector3(0, 0, 0))
            ref.current.scale.set(newScaleX, newScaleY, 1);
        }
    })

    return (
        <Suspense
            fallback={null}
        >
            <Image
                ref={ref}
                key={i}
                url={url}
                position={[x, y, 0]}
                scale={[widthRatio, heightRatio]}
                side={THREE.DoubleSide}
                toneMapped={false}
            />
        </Suspense>
    )
}

const ResponsiveOrthographicCamera: FC<any> = (props) => {
    const cameraRef = useRef<THREE.OrthographicCamera>(null);
    const { size, set } = useThree();

    const handleResize = useCallback(() => {
        if (cameraRef.current) {
            const aspect = size.width / size.height;
            cameraRef.current.left = -aspect * 1;
            cameraRef.current.right = aspect * 1;
            cameraRef.current.top = 1;
            cameraRef.current.bottom = -1;
            cameraRef.current.updateProjectionMatrix();
            set({ camera: cameraRef.current });
        }
    }, [size, set]);

    useEffect(() => {
        handleResize();
    }, [handleResize]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return <orthographicCamera ref={cameraRef} {...props} />;
};
