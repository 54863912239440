import { motion, stagger, useAnimate } from 'framer-motion';
import styled from 'styled-components';
import { useEffect } from 'react';
import { ANIMATION_EASE } from '../constants/animation.constants';

const LoadingWrapper = styled(motion.div)`
    width: calc(100vw - 4rem);
    height: calc((var(--vh, 1vh) * 100) - 4rem);
    background-color: #e8e8e8;
    position: fixed;
    padding: 2rem;
    top: 0;
    left: 0;
    z-index: 9999;
    text-transform: uppercase;
    pointer-events: none;
    display: block;

    #image-one {
        width: 150px;
    }

    #image-two {
        width: 100px;
    }

    @media screen and (min-width: 420px) {
        #image-one {
            width: 350px;
        }
        #image-two {
            width: 300px;
        }
    }
`

export const LoadingScreen = () => {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        animate([
            // STAGE 1
            [
                "#text-one",
                { left: '0%' },
                { delay: .1, ease: ANIMATION_EASE, duration: 1 }
            ],
           

            // STAGE 2
            [
                "#text-one",
                { left: '55%', x: 'calc(-55% - 4rem)' },
                { delay: .1, ease: ANIMATION_EASE, duration: 1 }
            ],
            [
                "#text-one #stage-1 span",
                { y: '-100%' },
                { ease: ANIMATION_EASE, duration: 1, at: '<', delay: stagger(0.1) }
            ],
            [
                "#text-one #stage-2",
                { y: '0%' },
                { ease: ANIMATION_EASE, duration: 1, at: '<' }
            ],
            [
                "#text-one #stage-2 span",
                { y: '0%' },
                { ease: ANIMATION_EASE, duration: 1, at: '<', delay: stagger(0.1) }
            ],

            // STAGE 3
            [
                "#text-one",
                { left: '100%', x: 'calc(-100% - 4rem)' },
                { delay: .1, duration: 1, ease: ANIMATION_EASE, }
            ],
            [
                "#text-one #stage-2 span",
                { y: '-100%' },
                { ease: ANIMATION_EASE, duration: 1, at: '<', delay: stagger(0.1) }
            ],
            [
                "#text-one #stage-3",
                { y: '0%' },
                { ease: ANIMATION_EASE, duration: 1, at: '<' }
            ],
            [
                "#text-one #stage-3 span",
                { y: '0%' },
                { ease: ANIMATION_EASE, duration: 1, at: '<', delay: stagger(0.1) }
            ],

            // OUT
            [
                "#text-one #stage-3 span",
                { y: '-100%' },
                { ease: ANIMATION_EASE, duration: 1, delay: stagger(0.1) }
            ],
            [
                "#test > div",
                { opacity: 0, transform: 'translateY(-20px)' },
                { delay: .1, at: '<' }
            ],
            [
                "#wrapper",
                { transform: 'translateY(-100%)' },
                { duration: 1, delay: .1, ease: ANIMATION_EASE }
            ],
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            ref={scope}
        >
            <LoadingWrapper
                id="wrapper"
            >
                <div
                    id="test"
                    style={{
                        position: 'absolute',
                        width: 200,
                        height: 100,
                        margin: 'auto',
                        left: '2rem',
                        top: '2rem',
                        textAlign: 'left',
                        overflow: 'hidden',
                    }}
                >
                    <div>
                        Brutal Geometric
                    </div>
                </div>

                <motion.div
                    id="text-one"
                    style={{
                        overflow: 'hidden',
                        position: 'absolute',
                        left: 'calc(10% - 200px)',
                        bottom: '2rem',
                        fontSize: '4rem',
                        fontWeight: 'bold',
                        // background: 'red',
                        width: '200px',
                        height: '4rem',
                        display: 'flex',
                        margin: '0 2rem',
                    }}
                >
                    <motion.div
                        id="stage-1"
                        style={{
                            y: '0%',
                            display: 'flex',
                            // background: 'yellow',
                            position: 'absolute',
                            bottom: 0,
                            left: '0%',
                            x: '0%'
                        }}
                    >
                        <motion.span style={{ display: 'inline-block' }}>0</motion.span>
                        <motion.span style={{ display: 'inline-block' }}>%</motion.span>
                    </motion.div>
                    <motion.div
                        id="stage-2"
                        style={{
                            y: '100%',
                            display: 'flex',
                            // background: 'green',
                            position: 'absolute',
                            bottom: 0,
                            left: '50%',
                            x: '-50%'
                        }}
                    >
                        <motion.span style={{ display: 'inline-block', y: '100%' }}>5</motion.span>
                        <motion.span style={{ display: 'inline-block', y: '100%' }}>5</motion.span>
                        <motion.span style={{ display: 'inline-block', y: '100%' }}>%</motion.span>
                    </motion.div>
                    <motion.div
                        id="stage-3"
                        style={{
                            y: '200%',
                            display: 'flex',
                            // background: 'blue',
                            position: 'absolute',
                            bottom: 0,
                            left: '100%',
                            x: '-100%'
                        }}
                    >
                        <motion.span style={{ display: 'inline-block', y: '100%' }}>1</motion.span>
                        <motion.span style={{ display: 'inline-block', y: '100%' }}>0</motion.span>
                        <motion.span style={{ display: 'inline-block', y: '100%' }}>0</motion.span>
                        <motion.span style={{ display: 'inline-block', y: '100%' }}>%</motion.span>
                    </motion.div>
                </motion.div>
            </LoadingWrapper>
        </div>
    )
}
